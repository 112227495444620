import { PostCoverImage} from '@monorepo-nx/std-watch/ui';
import moment from "moment";
import Image from "next/image";
import Link from "next/link";
import { SendEvent } from '@monorepo-nx/std-watch/services';

const handleClick = (e, linkText, href, section) => {
  SendEvent({eventName: 'click-component', component: 'horizontal-card', href, section, text: linkText})
}


const FeaturedPostContainer = ({href, children})=>{
  return (
    <Link legacyBehavior href={href} >
      <div className="max-w-8xl cursor-pointer hover:scale-105 ease-in-out duration-300 ">
        <div className="relative group">
          <div className="absolute -inset-0.5 bg-indigo-100 rounded-lg blur  transition duration-1000 group-hover:duration-200"></div>
          <div className="relative  bg-white ring-1 ring-gray-900/5 rounded-lg leading-none flex items-top justify-start ">
            {children}
          </div>
        </div>
      </div>
      </Link>
  )
}


const FeatureArticle = ({post, lgGridRows=3, blogPrefix='/blog'})=>{
  const href = `${blogPrefix}/${post.slug}`;
  const gridRowsClass = `lg:grid-rows-${lgGridRows}`;
  return (
    <FeaturedPostContainer href={href}>
  <div className="grid ${gridRowsClass} grid-cols-3 lg:grid-cols-4 gap-4 p-4" onClick={(e) => handleClick(e, post.title, href, "featured-post")} >

    <div className="order-1 row-span-3 col-span-3 lg:col-span-2">
      <PostCoverImage image={post.coverImage} 
        loading='eager'
        width={1000}
        height={760}
        />
    </div>

    <div className="order-2 row-span-1 col-span-3 sm:col-span-2 font-bold text-3xl text-gray-700 text-left pt-3 ">
      {post.title}
    </div>

  
     
    <div className="order-3 row-span-2 col-span-3 sm:col-span-2 font-light text-lg lg:pt-2 lg:pb-4">
      {post.excerpt}

      <div className="row-span-2 col-span-2 flex flex-row pt-4 xl:pt-0 gap-3 items-center ">
        <div className="pl-2 text-sm text-gray-500"> Updated at {moment(post.updatedAt).format('MMM DD, YYYY')}</div>
        <Link href={post.author ? "/medical-team/" + post.author.slug : ''}>
          <p className="text-sm text-gray-500  hover:text-gray-900 "> {post.author ? post.author.name : ''}</p>
        </Link>
      </div>
      <div className="flex flex-row pt-4 gap-3 items-center ">
        <div className="bg-gray-200 rounded-full py-1 px-2 text-xs">
          {post.category?.replaceAll('_', ' ')}
        </div>
        <div className="bg-gray-200 rounded-full py-1 px-2 text-xs">
          {post.subCategory?.replaceAll('_', ' ')}
        </div>
        <div className="text-gray-500 text-xs">
          3 min read
        </div>
      </div>
    </div>
  </div>
  </FeaturedPostContainer>
  ) 
}
export default FeatureArticle;
