import ChartSingleRow from "../chart-single-row/chart-single-row";
import { useContext } from 'react';
import { AppContext } from '@monorepo-nx/std-watch/services';

export function DefaultChart({chart}) {
  const { siteConfigData } = useContext(AppContext) || {};
  return (
    <>
      {chart?.promotions?.map((promotion,index) => (
        <div key={index} className="py-5">
          <ChartSingleRow promotion={promotion} callToAction={chart.callToAction ?? promotion.callToAction} position={index} section="chart" showNumber={true}/>
        </div>
      ))}
    </>
  );
}
export default DefaultChart;
