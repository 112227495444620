import { ChartSingleRow } from '@monorepo-nx/std-watch/ui';
import { useContext } from 'react';
import { AppContext } from '@monorepo-nx/std-watch/services';

export function TopProviderSection({ promotion, callToAction }) {
  const { siteConfigData } = useContext(AppContext) || {};
  
  return (
    <div className="bg-[#354276] ">
      { promotion ? (
        <div className="mx-auto max-w-6xl px-6 py-16 md:px-32 ">
          <p className='text-white text-3xl text-center mb-12'>Our Best Choice</p>
            <ChartSingleRow promotion={promotion} callToAction={callToAction} className="mx-auto max-w-8xl" />
        </div>  
      ) : '' }
    </div>
  );
}
export default TopProviderSection;
