import React, { useEffect, useState } from 'react'
import * as Sentry from "@sentry/nextjs";
import { AppContext, getDeeplinkByName } from '@monorepo-nx/std-watch/services';
import AffiliateLink from './affiliate-link';
import { useContext } from 'react';

export function AffiliateLinkContainer({ affName, children, href="", section, isButton="", affLink=undefined, dataAdvertiser="", dataTagCategory="", dataTagPosition="", dataTagLabel="", isDisabled=false, ...props }) {


  const endpoint = useContext(AppContext).endpointData;
  const { siteConfigData } = useContext(AppContext) || {};
  const domain = siteConfigData?.domain || "www.stdwatch.com";
  const [referralLink, setReferralLink] = useState('')

  useEffect(() => {
    async function updateDeeplink(name) {
      // console.info("Getting Referral Link", name)
      if (!affLink) {
        const results = await getDeeplinkByName(name, endpoint)
        if (!results || results.length === 0) {
          // "No affiliate link found", use the link as is, it may be an autogenerated links
          if (href && href.length >0) {
            setReferralLink(href)
          }
          return
        }
        affLink = results.affiliate_link;
      }
      if (affLink !== '') {
        if (!affLink.includes('{{sid}}')) {
          throw new Error(affName + " doesn't contain sid in the affiliate link: " + affLink)
        }
        else {
          let affLinkBase64 = Buffer.from(affLink, 'utf8').toString('base64')
          let referralLink = `https://${domain}/referral/${affName}/${affLinkBase64.replaceAll('/', '-')}`
          setReferralLink(referralLink)
        }
      }

    }


    updateDeeplink(affName)
      .catch((err) => {
        console.error(err)
        Sentry.setContext("AffiliateLink", {name: affName})
        Sentry.captureException(err);
      });
  }, [])


  let className
  if(isDisabled){
    return children
  }
  if (referralLink && isButton?.includes("ui orange button")) {
      className="inline-flex items-center rounded border border-transparent bg-orange-500 px-10 py-1.5 sm:text-sm font-medium text-white hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
  }
  else if (referralLink && isButton?.includes("ui blue button")) {
      className="inline-flex items-center rounded border border-transparent bg-indigo-500 px-10 py-1.5 sm:text-md font-medium text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
  }
  else if (referralLink && isButton?.includes("ui red button")) {
    className="inline-flex items-center rounded border border-transparent bg-red-500 px-10 py-1.5 sm:text-md font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
  }   
  const linkText = dataTagLabel || typeof children === "string" ? children : undefined
  
  const advertiser = dataAdvertiser || affName.split("/")[0]
  return (

    <AffiliateLink {...props} referralLink={referralLink} buttonClassName={className} linkText={linkText} affName={affName} section={section} advertiser={advertiser}>{children}</AffiliateLink>

  )
}

export default AffiliateLinkContainer;
