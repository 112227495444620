import moment from 'moment';
import Image from 'next/image'
import Link from 'next/link';
import { SendEvent } from '@monorepo-nx/std-watch/services';
import clsx from 'clsx';

const handleClick = (e, linkText, href, section) => {
  SendEvent({ eventName: 'click-component', component: 'horizontal-card', href, section, text: linkText, value: "" })
}
interface PostCoverImageProps {
  image: {
    url: string,
    width: number,
    height: number,
    altText: string
  },
  width?: number,
  height?: number,
  loading?: "lazy" | "eager" | undefined
}
export const PostCoverImage = ({ image, width, height, loading = "lazy" }: PostCoverImageProps) => {
  // height = height ? height : width*9/16  : 
  if (!width || !height) {
    console.warn("width is not defined for PostCoverImage, falling back to use original image dimensions")
  }

  return (
    <Image className="object-cover shadow-lg rounded-2xl"
      width={width ? width : image.width}
      height={height ? height : image.height}
      loading={loading}
      placeholder={loading === "eager" ? "empty" : "blur"
      }
      alt={image?.altText}
      blurDataURL="https://media.graphassets.com/AujNZF9cR2qpLAF4Nx1p"
      src={image ? image.url : "https://media.graphassets.com/TU7xL6CRRzyS8ghMrhlx"}
    // {...props}
    />
  )
}


export function PostCard({ scale = 1.0, post, section, showExcerpt = true, maxWidthPx = 800, className = "", featured = false, blogPrefix='/blog', ...props }) {
  const href = `${blogPrefix}/${post.slug}`;
  const imageProperties = { width: 350 * 1.3 * scale, height: 170 * 1.5 * scale }
  return (

    <div className="my-6 sm:px-4 flex flex-col items-center cursor-pointer md:hover:scale-105 ease-in-out duration-300 ">
      <div className="  ">
        <Link legacyBehavior href={href} >
          <div className="" onClick={(e) => handleClick(e, post.title, href, section)} >
            <PostCoverImage image={post.coverImage}
              width={imageProperties.width}
              height={imageProperties.height}
            />

            <div className="order-2 flex flex-col text-gray-700  ">
              <div className="font-bold text-xl text-gray-700 text-left pt-3 ">
                {post.title}
              </div>

              <div className="font-light text-left text-lg lg:pt-2 lg:pb-4">
                {showExcerpt ? post.excerpt?.slice(0, 120) : ''}...
              </div>
              <div className="flex flex-row pt-4 xl:pt-0 gap-3 items-center ">
                <div className="pl-2 text-sm text-gray-500"> Updated at {moment(post.updatedAt).format('MMM DD, YYYY')}</div>
                <Link href={post.author ? "/medical-team/" + post.author.slug : ''}>
                  <p className="text-sm text-gray-500  hover:text-gray-900 "> {post.author ? post.author.name : ''}</p>
                </Link>
              </div>
              <div className="flex flex-row pt-4 gap-3 items-center ">
                <div className="bg-gray-200 rounded-full py-1 px-2 text-xs">
                  {post.category?.replaceAll('_', ' ')}
                </div>
                <div className="bg-gray-200 rounded-full py-1 px-2 text-xs">
                  {post.subCategory?.replaceAll('_', ' ')}
                </div>
                <div className="text-gray-500 text-xs">
                  3 min read
                </div>
              </div>

            </div>

          </div>
        </Link>
        {/* <hr className="mt-5"></hr> */}
      </div>
    </div>
  )
};
export default PostCard;
